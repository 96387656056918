import { Controller } from "stimulus"
import RequestHelper from "../helpers/request_helper.js"

export default class extends Controller {
  static get targets() {
    return ['container', 'inventory', 'productHeading', 'variantHeading', 'productForm', 'variantForm'];
  }

  addVariant(event) {
    event.preventDefault()
    let url = event.target.href;
    fetch(url, RequestHelper.defaultPostOptions()).
      then(response => response.text()).
      then(json => JSON.parse(json).html).
      then(html => {
        this.inventoryTarget.style.display = "none";
        document.querySelector(".hidden-after-variant-create").style.display = "none";
        this.containerTarget.appendChild(RequestHelper.htmlNodeFromString(html))
      })
  }

  removeVariant(event) {
    event.preventDefault()
    let url = event.target.href;
    fetch(url, RequestHelper.defaultDeleteOptions()).
      then(() => {
        event.target.closest(".detail-body").remove();
        console.log(document.querySelectorAll(".detail-body").length)
        if (document.querySelectorAll(".detail-body").length === 1) {
          this.inventoryTarget.style.display = "block";
          document.querySelector(".hidden-after-variant-create").style.display = "block";
        }
      });
  }

  showEditProductDetails(event) {
    event.preventDefault()
    this.productHeadingTarget.style.display = "block";
    this.productFormTarget.style.display = "block";
    this.variantHeadingTarget.style.display = "none";
    this.variantFormTarget.style.display = "none";
  }

  showVariantDetails(event) {
    event.preventDefault()
    this.variantHeadingTarget.style.display = "block";
    this.variantFormTarget.style.display = "block";
    this.productHeadingTarget.style.display = "none";
    this.productFormTarget.style.display = "none";
  }


}
