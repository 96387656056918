import { Controller } from "stimulus"
import RequestHelper from "../helpers/request_helper.js"

export default class extends Controller {
  static get targets() {
    return ['container', 'modal'];
  }

  addProduct(event) {
    event.preventDefault();
    const url = $(event.target).closest('a').data("url");
    fetch(url, RequestHelper.defaultPostOptions())
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html;
      })
      .catch(this.logFailedCall);
  }

  removeProduct(event) {
    event.preventDefault();
    const url = $(event.target).closest('a').data("url");
    fetch(url, RequestHelper.defaultDeleteOptions())
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html;
      })
      .catch(this.logFailedCall);
  }

  logFailedCall(response) {
    console.warn(response);
  }

  loadProductInfo(event) {
    // called when the product is clicked and the modal is opened
    const variantUrl = $(event.target).closest('a').attr("href");
    this.retrieveVariant(variantUrl);
  }

  retrieveVariant(url) {
    fetch(url, RequestHelper.defaultRequestOptions())
      .then(response => response.text())
      .then(html => {
        this.modalTarget.querySelector(".modal-content").innerHTML = html;
      })
      .catch(this.logFailedCall);
  }

  changeVariant(event) {
    event.preventDefault();
    const url = $(event.currentTarget).data('path');
    this.retrieveVariant(url);
  }

  changeProductImage(event) {
    event.preventDefault();
    const url = $(event.currentTarget).data('path');
    this.retrieveVariant(url);
  }
}
