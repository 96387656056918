export default class {
  static defaultRequestOptions() {
    const csrfTokenTag = document.head.querySelector(`meta[name=csrf-token]`)
    let csrfToken = ""
    // no csrf token in feature specs
    if (csrfTokenTag !== null) {
      csrfToken = csrfTokenTag.getAttribute("content")
    }
    return {
      "credentials": "same-origin",
      "headers": {
        "X-CSRF-Token": csrfToken
      }
    }
  }

  static defaultPostOptions() {
    return Object.assign(this.defaultRequestOptions(), { "method": "POST" })
  }

  static defaultPutOptions() {
    return Object.assign(this.defaultRequestOptions(), { "method": "PUT" })
  }

  static defaultPatchOptions() {
    return Object.assign(this.defaultRequestOptions(), { "method": "PATCH" })
  }

  static defaultDeleteOptions() {
    return Object.assign(this.defaultRequestOptions(), { "method": "DELETE" })
  }

  static htmlNodeFromString(string) {
    let div = document.createElement("div")
    div.innerHTML = string
    return div.firstChild
  }
}
