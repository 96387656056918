import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['expandElement', 'formElement'];
  }

  toggleExpand(event) {
    this.expandElementTarget.classList.toggle('animate-expand');
    this.formElementTarget.classList.toggle('d-none');
    this.formElementTarget.classList.toggle('d-flex');
  }
}

