import { Controller } from "stimulus"
import RequestHelper from "../helpers/request_helper.js"

export default class extends Controller {
  static get targets() {
    return ['container', 'form'];
  }

  loadEdit(event) {
    event.preventDefault();
    const url = $(event.target).closest('a').data("url");
    this.originalContent = this.containerTarget.innerHTML
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html;
      })
      .catch(this.logFailedCall);
  }

  cancelEdit(event) {
    event.preventDefault()
    if (!!this.originalContent) {
      this.containerTarget.innerHTML = this.originalContent
    }
  }

  submitForm(event) {
    event.preventDefault()

    let url = this.formTarget.action;
    fetch(url, Object.assign(RequestHelper.defaultPostOptions(), {"body": new FormData(this.formTarget)})).
      then(response => response.text()).
      then(html => {
        if (html.length === 0) {
          location.reload();
        } else {
          this.containerTarget.innerHTML = html
        }
      })
      .catch(this.logFailedCall);
  }

  logFailedCall(response) {
    console.warn(response);
  }
}
