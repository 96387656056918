import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.updateTarget = _.debounce(this.updateTarget, 500)
  }

  updateTarget(event) {
    const element = document.querySelector(event.target.dataset.selector);

    $(element).fadeOut(() => {
      element.innerText = event.target.value;
      $(element).fadeIn();
    })
  }
}

