import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["label"];
  }

  updateLabel(event) {
    this.labelTarget.textContent = event.target.value.split(/(\\|\/)/g).pop();
  }
}

