import { Controller } from "stimulus"
import RequestHelper from "../helpers/request_helper.js"

export default class extends Controller {
  static get targets() {
    return ["container"];
  }

  addRow(event) {
    event.preventDefault();
    let url = event.target.href;
    fetch(url, RequestHelper.defaultPostOptions()).
      then(response => response.text()).
      then(json => JSON.parse(json).html).
      then(html => {
        this.containerTarget.appendChild(RequestHelper.htmlNodeFromString(html));
        document.getElementsByClassName('message-container')[0].classList.add('d-none');
        document.getElementsByClassName('header-container')[0].classList.remove('d-none');
      })
  }

  recipientValueChanged(event) {
    const parameterName = event.target.dataset.parameter;
    const formData = new FormData();
    formData.append(parameterName, event.target.value);

    fetch(
      event.target.dataset.path,
      Object.assign(RequestHelper.defaultPatchOptions(), { "body": formData })
    );
  }
}

