import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['modal', 'form'];
  }

  load() {
    fetch(this.data.get("url"), {"credentials": "same-origin"}).
      then(response => response.text()).
      then(html => {
        this.modalTarget.innerHTML = html
      })
  }

  submitForm(event) {
    event.preventDefault()

    let url = this.formTarget.action;
    fetch(url, {
      "credentials": "same-origin",
      "method": "POST",
      "body": new FormData(this.formTarget)
    }).
      then(response => response.text()).
      then(html => {
        if (html.length === 0) {
          location.reload();
        } else {
          this.modalTarget.innerHTML = html
        }
      })
  }
}
