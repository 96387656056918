import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['errorContainer', 'completeContainer', 'form', 'importTable', 'importClose'];
  }

  handleError(errorText, el) {
    $(el).removeAttr("disabled")
    $(this.errorContainerTarget).text(errorText)
  }

  beginStream(importId, channelName) {
    $(this.formTarget).addClass('d-none')
    $(this.importCloseTarget).addClass('d-none')
    $(this.importTableTarget).removeClass('d-none')

    let subscription
    let controller = this
    let receiveHandler = function(data) {
      if(data.event == 'import') {
        $(controller.importTableTarget).append(data.html);
      } else {
        $(controller.completeContainerTarget).append(data.html);
        subscription.unsubscribe();
      }
    };

    subscription = App.cable.subscriptions.create({ channel: channelName, id: importId }, {
      received: receiveHandler
    });
  }

  submitCSVForm(event) {
    event.preventDefault()
    let btn = event.target
    $(btn).attr('disabled', 'disabled')

    let url = this.formTarget.action;
    fetch(url, {
      "credentials": "same-origin",
      "method": "POST",
      "body": new FormData(this.formTarget)
    }).
      then(response => response.text()).
      then(html => {
        let json = JSON.parse(html)
        if (json.status != "ok") {
          this.handleError(json.error, btn);
          return;
        }

        this.handleError(status, btn);
        this.beginStream(json.importId, json.channelName);
      })
  }
}
