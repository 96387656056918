import { Controller } from "stimulus"

export default class extends Controller {
  visitCollection(event) {
    if (!(event.target.localName === "i" || event.target.localName === "button" || event.target.localName === "a")) {
      event.preventDefault();
      window.location.href = event.currentTarget.dataset.href;
    }
  }
}
