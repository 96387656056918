import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['password', 'lengthCheck', 'lowercaseCheck', 'uppercaseCheck', 'specialCheck', 'icon'];
  }

  checkPasswordRequirements() {
    const password = this.passwordTarget.value
    const checkClass = "satisfied"
    const uncheckedClass = "unsatisfied"

    if (this.verifyLength(password)) {
      this.lengthCheckTarget.classList.add(checkClass)
      this.lengthCheckTarget.classList.remove(uncheckedClass)
    } else {
      this.lengthCheckTarget.classList.remove(checkClass)
      this.lengthCheckTarget.classList.add(uncheckedClass)
    }

    if (this.verifyLowercase(password)) {
      this.lowercaseCheckTarget.classList.add(checkClass)
      this.lowercaseCheckTarget.classList.remove(uncheckedClass)
    } else {
      this.lowercaseCheckTarget.classList.remove(checkClass)
      this.lowercaseCheckTarget.classList.add(uncheckedClass)
    }

    if (this.verifyUppercase(password)) {
      this.uppercaseCheckTarget.classList.add(checkClass)
      this.uppercaseCheckTarget.classList.remove(uncheckedClass)
    } else {
      this.uppercaseCheckTarget.classList.remove(checkClass)
      this.uppercaseCheckTarget.classList.add(uncheckedClass)
    }

    if (this.verifySpecial(password)) {
      this.specialCheckTarget.classList.add(checkClass)
      this.specialCheckTarget.classList.remove(uncheckedClass)
    } else {
      this.specialCheckTarget.classList.remove(checkClass)
      this.specialCheckTarget.classList.add(uncheckedClass)
    }
  }

  togglePasswordVisibility() {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text";
      this.iconTarget.classList.remove('icon-lasso-show');
      this.iconTarget.classList.add('icon-lasso-hide');
    } else {
      this.passwordTarget.type = "password";
      this.iconTarget.classList.remove('icon-lasso-hide');
      this.iconTarget.classList.add('icon-lasso-show');
    }
  }

  verifyLength(password) {
    if (password.length >= 10) {
      return true
    }
    return false
  }

  verifyLowercase(password) {
    if (password.match(/[a-z]/)) {
      return true
    }
    return false
  }

  verifyUppercase(password) {
    if (password.match(/[A-Z]/)) {
      return true
    }
    return false
  }

  verifySpecial(password) {
    if (password.match(/[\d\W_]/)) {
      return true
    }
    return false
  }
}
